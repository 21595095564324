$( document ).ready(function() {
  $('#vid2').hide();
  $('#vid3').hide();
  $('#vid4').hide();
  $('#vid5').hide();
  $('.expandbtn').click(function () {
    $('#accordionDatosActuales .collapse').removeAttr("data-parent");
    $('#accordionDatosActuales .collapse').collapse('show');
  });
  $('.collapsebtn').click(function () {
    $('#accordionDatosActuales .collapse').attr("data-parent","#accordionDatosActuales");
    $('#accordionDatosActuales .collapse').collapse('hide');
  });
  $('.tabclick').click(function (event) {
    event.preventDefault();
    var strAncla=$(this).attr('href'); //id del ancla
    var tab = $(this).data('go');
    //console.log(strAncla);
    //console.log(tab);
    $('#YearTabs a[href="#Year2019"]').tab('show');
    $(tab).collapse('toggle');
    $(tab).on('shown.bs.collapse', function(){
      $('html, body').animate({scrollTop: $(strAncla).offset().top - 70});
    });
  });
  $('.clvidbtn').click(function(e){
    e.preventDefault();
    var video = $(this).data('vid');
    $('.vframe').hide();
    $(video).show();
  });

  $('#modalSeleccionNivel').on('show.bs.modal', function (event) {
    console.log('modal')
    var button = $(event.relatedTarget) // Button that triggered the modal
    var rol = button.data('rol')
    var medroute = '/'+rol+'/media'
    var basroute = '/'+rol+'/basica'

    var modal = $(this)
    modal.find('.modal-body #routemodbas').attr('href', basroute);
    modal.find('.modal-body #routemodmed').attr('href', medroute);
  });

});

// When the user scrolls down 20px from the top of the document, show the button
// window.onscroll = function() {scrollFunction()};
//
// function scrollFunction() {
//   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
//     document.getElementById("myBtn").style.display = "block";
//   }
//   if (document.body.scrollTop > 650 || document.documentElement.scrollTop > 650) {
//     document.getElementById("botones-acordeon").classList.add("show");
//   }
// }

// function textFunction(size) {
//   event.preventDefault();
//   var parrafo = document.getElementsByTagName("p");
//   var list = document.getElementsByClassName("events");
//   for (let item of parrafo) {
//     item.removeAttribute("class");
//     item.classList.add(size);
//   }
// }
